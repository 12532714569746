import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  BrowserRouter,
  Redirect,
} from "utils/react-router-dom";

import TopBarWhite from "./components/common/headers/topbarWhite";
import { Helmet } from "utils/react-helmet";
//region
import { getCookie } from "utils/cookie.js";
import ReactGa from "react-ga";

const DefaultLayout = React.lazy(() =>
  import("places/containers/DefaultLayout.jsx")
);
const ProcessApp = React.lazy(() => import("./cra-pages/BookingProcess.jsx"));
const Download = React.lazy(() => import("./cra-pages/Download.jsx"));
const ErrorPage = React.lazy(() => import("./cra-pages/404.jsx"));
const SitterOnePage = React.lazy(() => import("./cra-pages/SitterOnePage.jsx"));
const ServiceOnePage = React.lazy(() => import("./cra-pages/ServiceOnePage.jsx"));

const App = (router) => {
  const region = getCookie("region");
  // 因為沒有打API所以在這邊直接把 json 透過 dispatch 放到 store 中

  useEffect(() => {
    ReactGa.initialize("UA-144101104-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.replace("/tw");
      return
    }

    if (window.location.pathname.split("/")[1] === "en") {
      document.cookie = `region=en`;
    } else if (window.location.pathname.split("/")[1] === "tw") {
      document.cookie = `region=tw`;
    }

    if (
      window.location.pathname === "/process" ||
      window.location.pathname === "/proccess"
    ) {
      const htmlTag = document.getElementsByTagName("html");
      htmlTag[0].classList.add("noshow-smartbanner");
      document.cookie = `region=tw`;
      return;
    }

    if (
      window.location.pathname === "/catwash" ||
      window.location.pathname === "/dogwash"
    ) {
      window.location.replace("/service/bathing");
    }

    if (window.location.pathname === "/walk") {
      window.location.replace("/service/walking");
    }

    if (window.location.pathname === "/index.html") {
      window.location.replace("/");
    }
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://www.fluv.com/${region}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <TopBarWhite />
      <BrowserRouter>
        <Router>
          <Switch>
            <Route
              exact
              path="/404"
              name="ErrorPage"
              render={(props) => <ErrorPage {...props} />}
            />
            <Route
              exact
              path="/process"
              name="ProcessApp"
              render={(props) => <ProcessApp {...props} />}
            />
            <Route
              exact
              path="/proccess"
              name="ProcessApp"
              render={(props) => <ProcessApp {...props} />}
            />
            <Route
              exact
              path="/download"
              name="Download"
              render={(props) => <Download {...props} />}
            />
            <Route
              exact
              path="/tw/download"
              name="Download"
              render={(props) => <Download {...props} />}
            />
            <Route
              exact
              path="/en/download"
              name="Download"
              render={(props) => <Download {...props} />}
            />
            <Route
              exact
              path="/app/u/:userId/share"
              name="AppSitterDescription"
              render={(props) => <SitterOnePage {...props} />}
            />
            <Route
              exact
              path="/app/u/:userId/service/:serviceId"
              name="AppSitterService"
              render={(props) => <ServiceOnePage {...props} />}
            />
            <Route
              path="/"
              name="Default"
              render={(props) => (
                <>
                  <DefaultLayout {...props} />
                </>
              )}
            />
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
};

export default App;
